:root{
	--orange: #dd4814;
	--green: #38b44a;
	--black3: #333333;
	--blue: #3a87ad;
	--darkslate: #2f4f4f;
	--white: #fff;
	--gary: #aea79f;
}

.bg-orange{
	background-color: var(--orange) !important;
}
.bg-gary{
	background-color: var(--gary) !important;
}
.bg-green{
	background-color: var(--green);
}
.bg-black3{
	background-color: var(--black3);
}
.text-orange{
	color: var(--orange);
}
.text-green{
	color: var(--green);
}
.text-black3{
	color: var(--black3);
}




body{
	font-family: 'Ubuntu', sans-serif;
}

.menu-toggel {
    width: 42px;
    height: 34px;
    border: 2px solid var(--orange);
    border-radius: 4px;
    position: absolute;
    top: 20px;
    left: 24px;
    padding: 5px 0 0 6px;
    display: none;
}
.menu-toggel span {
    width: 27px;
    border-bottom: 3px solid var(--orange);
    display: block;
    margin: 3px 0;
}

.login-heading h2 {
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    margin-top: 50px;
    color: #333;
}
.login-box {
    max-width: 500px;
    margin: 50px auto;
    border: 1px solid #c6c4c4;
    padding: 20px 45px;
}
.login-inner-logo img {
    max-width: 106px;
    width: 100%;
}
.login-inner-logo {
    text-align: center;
}
.succe-massge p {
    font-size: 14px;
    color: var(--blue);
    padding: 15px 0;
    border: 1px solid #3a87ad38;
    text-align: center;
    background: #bce8f1;
    margin: 15px 0;
    border-radius: 4px;
}
.login-form input::placeholder{
	color: var(--darkslate);
}
.login-form input {
    box-shadow: none !important;
    outline: none !important;
    margin-bottom: 20px;
    width: 100%;
    padding: 8px 10px 10px 56px;
    border-radius: 4px;
    border: 1px solid #c8c8c8;
    position: relative;
    color: var(--darkslate);
}
span.icon-u{
	position: relative;
}
span.icon-u i {
    position: absolute;
    z-index: 1;
    top: -11px;
    height: 43px;
    border-right: 1px solid #c8c8c8;
    padding: 10px 14px;
    color: var(--darkslate);
}
span.icon-l{
	position: relative;
}
span.icon-l i {
    position: absolute;
    z-index: 1;
    top: -11px;
    height: 43px;
    border-right: 1px solid #c8c8c8;
    padding: 10px 14px;
    color: var(--darkslate);
}
.submit-btn{
	text-align: center;
}
.submit-btn button {
    padding: 10px 20px;
    margin-top: 0;
    width: 50%;
    background-color: #37ce8a;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    border: 1px solid var(--black3);
    margin-bottom: 18px;
}


/*dassbord*/
.top-logo img {
    max-width: 72px;
    margin-left: 26px;
}
.top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px;
    border-bottom: 2px solid#bf3e11;
}
.top-header-logout p {
    margin: 0 15px 0 0;
    background: #aea79f;
    padding: 8px 12px;
    border-radius: 4px;
    color: #ffffff;
}
.top-header-logout{
	position: relative;
}
.logout-btn {
    position: absolute;
    top: 43px;
    right: 13px;
    width: 228px;
    display: block;
    text-align: left;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
}
.logout-btn-show {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s;
}
.logout-btn p {
    background: #fff;
    color: var(--black3);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    display: block;
    width: 100%;
    border: 1px solid #7472729e;
}
.logout-btn p:hover {
    background: var(--orange);
    color: var(--white);
}

.sidebar {
    float: left;
    background-color: #f8f9fa;
    width: 182px;
    margin: 10px;
}

.navigation-links{
    font-size: 14px !important;
    color: var(--orange) !important;
    text-decoration: none;
    transition: all 0.3s;
}

.sidebar button {
    display: block;
    background-color: inherit;
    color: var(--orange);
    padding: 10px 12px;
    width: 90%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 14px;
    margin: 2px 10px;
    border-radius: 4px;

    border-bottom: beige;
    border-style: groove;
}
.sidebar-inner p {
    margin: 2px 15px;
    font-size: 11px;
}

.sidebar button:hover {
  background-color: var(--orange);
   color: var(--white);
}

.sidebar button.active {
    background-color: var(--orange);
    color: var(--white);
}

.tabcontent {
    float: left;
    padding: 0px 12px;
    border: 1px solid #ccc;
    width: calc(100% - 223px);
    border-left: none;
}

/*subscribers*/
.subscribers-home-l {
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 26px 0;
}
.subscribers-home-l a{
	font-size: 14px;
	color: var(--orange);
	text-decoration: none;
	transition: all 0.3s;
}
.subscribers-home-l a:hover{
	text-decoration: underline;
	transition: all 0.3s;
}
.subscribers-home-l span {
    color: #bfbdbd;
    font-size: 14px;
}

/*date*/
.date-inner {
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
    align-items: center;
    justify-content: center;
}
.date-form label {
    font-size: 14px;
    font-weight: 600;
    color: var(--black3);
}
.date-form label {
    padding-right: 12px;
}
.date-form input, .date-form select {
    font-size: 14px;
    font-weight: 400;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #cccccc;
    outline: none;
}
.start-date {
    padding-right: 101px;
}
.date-search-btn button {
    border: none;
    background: var(--green);
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 14px;
    color: var(--white);
}

.date-search-btn-my button {
    border: none;
    background: var(--green);
    border-radius: 4px;
    /* padding: 8px 12px; */
    font-size: 14px;
    color: var(--white);
}

.title-ic p {
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 8px 12px;
    color: var(--black3);
    font-size: 14px;
    font-weight: 600;
    margin: 35px 0 15px 0;
}

.title-ic div {
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 8px 12px;
    color: var(--black3);
    font-size: 14px;
    font-weight: 600;
    margin: 35px 0 15px 0;
}

.nomber-slect {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/*table*/
.main-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 15px;
    overflow-y: hidden;
    overflow-x: auto;
}

.main-table td, th {
    border: 1px solid #dddddd;
    padding: 8px;
    font-size: 14px;
    color: var(--black3);
    text-align: center;
    position: relative;
}
table.main-table th:after {
    position: absolute;
    content: '\f160';
    font-family: 'FontAwesome';
    right: 16px;
    color: #959595;
    font-size: 14px;
}
/*pagination-sec*/
.pagination-sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 31px 0;
}
.pagination-sec ul li a {
    border: 1px solid #cccccc !important;
    border-radius: 0px !important;
    color: red;
}
.pagination-sec ul li a:focus, .pagination-sec ul li a:hover	 {
    color: var(--black3) !important;
    border: 1px solid var(--black3) !important;
    box-shadow: none;
}


.unSubscription-table .main-table th:after{
	display: none;
}

.c-box {
    border: 1px solid #cccc;
    padding: 0 0 40px 0;
}
.tabcontent {
    border: none !important;
}
.c-box .title-ic p {
    margin: 0 0 48px 0;
    border-width: 0 0 1px 0;
}
.c-box .date-inner {
    display: flex;
    flex-wrap: wrap;
}
.c-box .end-date {
    width: 100%;
    text-align: center;
}
.text-file-p {
    width: 100%;
    text-align: center;
    color: red;
}
.end-date input {
    width: 416px;
}

.table-sec {
    overflow-y: hidden;
    overflow-x: auto;
}
/*ss*/
.date-inner.date-1-sec {
    display: flex;
    gap: 24px;
    justify-content: space-evenly;
    align-items: center;
}
 .ss .end-date {
    width: 36%;
}
 .ss .end-date input {
    width: 72%;
}







@media only screen and (max-width: 768px){
	.menu-toggel {
	    display: block !important;
	}
	.sidebar {
	    display: none;
	    position: absolute;
    	left: 0;
	    background-color: #484a4abf;
   	    width: 329px;
	}
	.sidebar.sidebar-show {
	    display: block;
	}
	.tabcontent {
	    width: 100%;
	}
	.top-logo img {
	    display: none;
	}
	.end-date {
	    width: 100%;
	}
	.date-inner input, .date-inner select, .date-inner label{
		width: 100%;
	}
	table.main-table th:after {
	    right: 2px;
	}
	.main-table td, th {
	    text-align: left;
	}
	 .ss .end-date {
	    width: 100%;
	}
	 .ss .end-date input {
	    width: 100%;
	}
}